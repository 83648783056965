
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiProject from "@/request/apis/api_project";
import StorageUtil from "@/utils/storage_util";
import PageUtil from "@/utils/page_util";

interface IState {
  list: any[],
  total: number;
  loading: boolean;
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: '类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '区域',
    dataIndex: 'addr',
    key: 'addr',
  },
];

export default defineComponent({
  name: 'ProjectSpaceList',
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      searches: {
        name: '',
        phone: '',
        type: '',//0我有场地1我要场地
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否启用pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      StorageUtil.setParams(state.searches)
      state.loading = true
      const res = await ApiProject.getSpaceFormList({...state.searches})
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.lists || []
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page = PageUtil.pageNo
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = PageUtil.pageNo
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      getList,
    }
  }
})
